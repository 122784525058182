<template>
    <div>
    <base-header base-title="Caller"></base-header>
    <fullscreen ref="fullscreen" @change="fullscreenChange">
    <div class="kt-content kt-grid__item kt-grid__item--fluid"  id="kt_content">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <option-caller
                v-if="callerMode == callerType.OPTION_CALLER"
                id="option_caller"
                @clickToggle="toggle">
            </option-caller>
            <recent-caller
                v-if="callerMode == callerType.RECENT_CALLER"
                @clickToggle="toggle">
            </recent-caller>
            <unit-caller
                v-if="callerMode == callerType.UNIT_CALLER"
                @clickToggle="toggle">
            </unit-caller>
            <doctor-caller
                v-if="callerMode == callerType.DOCTOR_CALLER"
                @clickToggle="toggle">
            </doctor-caller>
        </div>
      </div>
    </div>
    </fullscreen>
  </div>
</template>

<script>
    import BaseTitle from "./../_base/BaseTitle.vue";
    import BaseHeader from "./../_base/BaseHeader.vue";
    import Fullscreen from "vue-fullscreen/src/component.vue";
    import UnitCaller from "./UnitCallers/UnitCaller.vue";
    import DoctorCaller from "./DoctorCallers/DoctorCaller.vue";
    import { mapState, mapMutations, mapActions } from 'vuex';
    import CallerType from "./../../constants/caller-type-enum";
    import OptionCaller from './OptionCaller';
    import RecentCaller from './RecentCaller';
    import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
    import { uuid } from 'vue-uuid';
    import { fromEventPattern  } from 'rxjs';
    import { debounceTime } from 'rxjs/operators';
    import CheckPermission from "./../../services/checkPermission.service";
    const checkPermission = new CheckPermission();
    import qz from "qz-tray";
    import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
    const PrinterResourceRepository = RepositoryFactory.get("printerResource");

    const { RSAKey, KJUR, KEYUTIL, stob64, hextorstr } = require('jsrsasign');
    
    export default {
        components: {
            BaseTitle,
            BaseHeader,
            Fullscreen,
            UnitCaller,
            DoctorCaller,
            OptionCaller,
            RecentCaller,
        },
        data() {
          return {
            fullscreen: false,
            callerType: CallerType,
            timeOutTick: '',
            tickAll: [],
            isCheckTelekonsul: false,
            msgData: ''
          }
        },
        beforeRouteLeave(to, from ,next) {
            // console.log(to, from)
            sessionStorage.removeItem('viewCaller')
            next()
        },
        computed: {
            ...mapState('CallerStore', {
                callerMode: state => state.viewCaller,
                historyDokterCaller: state => state.historyDokterCaller,
                historyUnitCaller: state => state.historyUnitCaller,
                queueDevice: state => state.queueDevice,
                userNotification: state => state.userNotification,
                unitQueue: state => state.unitQueue
            }),
            ...mapState("DoctorAppointmentStore", {
                stompClient: state => state.stompClient,
            }),
            ...mapState('DoctorCallerStore', {
                selectedDate: state => state.selectedDate,
                dokter: state => state.dokter,
                jadwal: state => state.jadwal,
            }),
             ...mapState('UnitCallerStore', {
                 unitId: state => state.unit,
             })
        },
        methods: {
            ...mapMutations({
                setViewCaller: 'CallerStore/SET_VIEW_CALLER',
                setSessionId: 'CallerStore/SET_SESSION_ID',
                SET_STOMP_CLIENT: 'DoctorAppointmentStore/SET_STOMP_CLIENT',
                setListPrinter: "CallerStore/SET_LIST_PRINTER",
                setPrintingOption: "CallerStore/SET_PRINTING_OPTION",
                setTenantPrinter: "CallerStore/SET_TENANT_PRINTER"
            }),
            ...mapActions({
                saveUserNotification: 'CallerStore/POST_USER_NOTIFICATION',
                deleteQueueDevice: 'CallerStore/DELETE_QUEUE_DEVICE',
                getDataFromApi: 'UnitCallerStore/GET_DATA_FROM_API',
                getDoctorCallerQueue: 'DoctorCallerStore/GET_DATA_QUEUE',
            }),
            toggle () {
                this.$refs.fullscreen.toggle();
            },
            fullscreenChange (isFullScreen) {
                this.fullscreen = isFullScreen
            },
            async initPrinter(){
                let vx = this
                // ini daftarinnya qz sertifikat biar prompt hanya untuk pertama kali
                
                qz.security.setCertificatePromise(function (resolve, reject) {
                resolve("-----BEGIN CERTIFICATE-----\n" +
                    "MIID2DCCAsCgAwIBAgIJALt852c596t1MA0GCSqGSIb3DQEBCwUAMIGAMQswCQYDVQQGEwJJRDESMBAGA1UECAwJSW5kb25lc2lhMRAwDgYDVQQHDAdKYWthcnRhMR8wHQYDVQQKDBZQVCBNYW5kYXlhIFNlaGF0IFV0YW1hMQwwCgYDVQQLDANNU1UxHDAaBgNVBAMMEyoubWFuZGF5YWhlYWx0aC5jb20wIBcNMjAwODExMTAzNDE0WhgPMjA1MjAyMDQxMDM0MTRaMIGAMQswCQYDVQQGEwJJRDESMBAGA1UECAwJSW5kb25lc2lhMRAwDgYDVQQHDAdKYWthcnRhMR8wHQYDVQQKDBZQVCBNYW5kYXlhIFNlaGF0IFV0YW1hMQwwCgYDVQQLDANNU1UxHDAaBgNVBAMMEyoubWFuZGF5YWhlYWx0aC5jb20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQC7qAv8vXSzThYEYEQuMFhX2X6WPbDk9sW+vKxNgdhOy73B7IWg5vem0lOdiXzqdWauAR45F3Wti5g1PcECJL0mxIzfki9T+9wNgvsGz3r++01twOtVxv8teIEQAes4m+J/nULDWfivam2VyAWn2tpo7w56NKtHnMfeZjJ22lXjtRw6nqCxsLltk+mUwMX7X0KMOn5dzSv/99vr04UbiPNi/a1XKjPgNGS59bWeEJAy1OA5iz2/DzAHlOJn4rHliIR60eHRLGhl1rM4zni3/4bHHomL1QK1JWyHE8RndRy+equGdgqN7tbkiyLPSGyjaZ6yTP5dMbPEJnRQPOEQbN13AgMBAAGjUTBPME0GA1UdEQRGMESCFmRlbW8ubWFuZGF5YWhlYWx0aC5jb22CGmludGVybmFsLm1hbmRheWFoZWFsdGguY29tgg4qLnF6dHJheS5sb2NhbDANBgkqhkiG9w0BAQsFAAOCAQEAZmjJB1RNUSk+A2xIvtLUonVsRbI8BpXh49O5Qbj5Pz5g/0mx8qq83pRq3etORBC85X3PKRU2PFmPOCiVuN8AZojXFUunDSx4pOVWypfmgirRk8l91TeCVXaAoeKsgYxg8xH7u02gIg1O5E0XZcy4KcoUCvDkqYq55IV07ZcFLFqhAxZTyH15IiJ/p9dgSgM3NWSEGp7y8MPXXtl3ODwpLlxJiawzfgwl4evjSZSst5ASOAY9PdjYuh4BKtRtRv8wmyYCHgw90ZE6aNOxhR3DDK5NxeoJcOVDNl3SZ3CZesx57tU+oGuNLJd/AiTZE8PwDljjoIQXLKx/iVi+A9xnDQ==\n" +
                    "-----END CERTIFICATE-----");
                });

                var privateKey = "-----BEGIN PRIVATE KEY-----\n" +
                    "MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQC7qAv8vXSzThYEYEQuMFhX2X6WPbDk9sW+vKxNgdhOy73B7IWg5vem0lOdiXzqdWauAR45F3Wti5g1PcECJL0mxIzfki9T+9wNgvsGz3r++01twOtVxv8teIEQAes4m+J/nULDWfivam2VyAWn2tpo7w56NKtHnMfeZjJ22lXjtRw6nqCxsLltk+mUwMX7X0KMOn5dzSv/99vr04UbiPNi/a1XKjPgNGS59bWeEJAy1OA5iz2/DzAHlOJn4rHliIR60eHRLGhl1rM4zni3/4bHHomL1QK1JWyHE8RndRy+equGdgqN7tbkiyLPSGyjaZ6yTP5dMbPEJnRQPOEQbN13AgMBAAECggEAEHG+cyxrAJd0KHgDIYUNCM2/YQ0Q5k+Vrow8ESKywNzryQWgBCSZSR4fQ6vbrXQSRmdErb4Xitizwt6hfj2X8cDBk4ns6dAlTW3P753Y6mU9OMPyG3cvv11S+gMhCLnoVESEqDfHKiAKcFix3i64ev+P5jedVs4vWZbdDzs80ZJTuHSQUZNp7/i61Cyyv6d8J+2jqntJvid9eaZoDzNrfpwlKp0GCoj0x3YqAoT/476eyCsZdaGxjac5sp+wjqn7imFeCNJ0t78JoyKNjbg9nEkxGpRHqLWDpAExo7FpNN39jCSb5tzFYAQg4hz2krRpsrDAoCm58jr524zhmH0ZkQKBgQD1in/yEjBoriku4DopYcEscYy9Bp/mWFqmaombxQJ3AvWbpfjGn/pIgy6Xt262dGbHT4RjNh6K0IaqOCho18wBAHzcTi+NyBGpy3EJ9Pm7AassURBVHv6yAYNS4rbSPWQuwpDGDgziH3WhXUdiIuI2zs4x9edt/VPIk2XnlIqvSQKBgQDDplhUN3kG0tKC2w+CD8cxoj5VU9XwDJGAhSwO+ka2Dh3r2573JObq2Yb+2HTmU2Feo/VlfYWQyKaQivUeHHeLnnzDzFaETuGMYkHPNk4sNdXng7ja1OgVbwQiT9+6PnAceZ/+lVaZ2CPdFTQjnPgTdQQRDeZIoaZnKAMZKTVmvwKBgQDvLPAgfnNFJJDdJnHBKYWfoyPyZOeQ2vPCMvjEEQ2rm+heqzc/U0nbJF1YT8e6VnxfHSfZ9DegBCuI15cVOU9wkmcNueM8emwqNw9vIX+5F5EDZBtCryRthGfFby2icUaK/d9D7R/Di91rR49nGo+7z4quw9ehHihksp+LCvhkcQKBgA/JVEc3UqSiTioQ0ueDrmbq2Gy6HwEHgpYEKNHsj9gQu4lUZzc52wgIFURq4sPS5oBkamCOCEDub8Ip9C1BzsONPjPuQOzcEIbrRrQZhaU7ks7jaf7+Ptu74XGDUGIOt60UgzxfgGVZc0tbcqGNANdREcrufC5QHMUmDVnyYUSxAoGBAKkO4oHHkNeIME4IKNbKytvqVlDQojA6ca+0MqB06W2R3Y3IqW6d8xGUr6UwDFqxLTcou5sGFe1WQ2XEMpSRa0XwPio/5DUr2RIxXyj1ijQ7HIT7+COKaeGFekBLcrMGx9vrNptMOmPyrGki8usjJ4QyNQ7HFwLjad/0jrIqspxa\n" +
                    "-----END PRIVATE KEY-----";
                function strip(key) {
                    if (key.indexOf('-----') !== -1) {
                        return key.split('-----')[2].replace(/\r?\n|\r/g, '');
                    }
                }
                
                qz.security.setSignaturePromise(function (toSign) {
                    return function (resolve, reject) {
                        try {
                            var pk = KEYUTIL.getKey(privateKey);
                            var sig = new KJUR.crypto.Signature({"alg": "SHA1withRSA"});
                            sig.init(pk)
                            sig.updateString(toSign)
                            var hex = sig.sign()
                            resolve(stob64(hextorstr(hex)));
                        } catch (err) {
                            console.error(err);
                            reject(err);
                        }
                    };
                });
                
                let host = 'localhost'
                if(localStorage.selectedServer){
                    host = localStorage.selectedServer
                }
                const options = {
                    host: `${host}`,
                    port: {
                        secure: [8181]
                    },
                    usingSecure: true
                }
            
                if(!qz.websocket.isActive()){
                    await qz.websocket.connect(options)
                        .then(() => {
                            if(!localStorage.printingOption || localStorage.printingOption !== 'DIALOG'){
                                vx.setPrintingOption('SERVER')
                            } else {
                                localStorage.printingOption = 'DIALOG'
                            }
                            return qz.printers.find()
                        })
                        .then((data) => {
                            if(data) {
                                localStorage.printerList = JSON.stringify(data)
                            } else {
                                toastr.error("Can't find Printer", '', {preventDuplicates: true})
                            }
                        })
                        .catch(_ => {
                            vx.setPrintingOption('DIALOG')
                        })
                }
            },
            async onInit(){
                window.onunload = (e) => {
                   this.deleteQueueDevice();
                }
                try{
                    let viewType = (this.historyUnitCaller.length > 0 || this.historyDokterCaller.length > 0) ? CallerType.RECENT_CALLER : CallerType.OPTION_CALLER;
                    let sessionId = uuid.v4();
                    if(sessionStorage.getItem('sessionId') != undefined){
                        sessionId = sessionStorage.getItem('sessionId');
                    }
                    // resolve bug ganti unit caller error
                    if (this.userNotification == null) {
                        this.saveUserNotification({notificationId: sessionId});
                    }
                    this.setViewCaller(viewType);
                    this.setSessionId(sessionId);
                    this.initPrinter()
                } catch(err){
                    console.log(err);
                }
            },
            connect(){

                let base = process.env.VUE_APP_WEB_SOCKET;
                let url = `${base}/?access_token=${localStorage.tokens}`
                var socket = new SockJS(url);

                this.SET_STOMP_CLIENT(Stomp.over(socket))
                // to turn the console log off
                this.stompClient.debug = () => {};

                this.stompClient.connect(
                    {},
                    frame => {
                    this.stompClient.subscribe("/topic/webbo", tick => {
                    let viewCaller = sessionStorage.getItem('viewCaller')
                    if(viewCaller) {
                        let msg = JSON.parse(tick.body)
                        var event = new CustomEvent("basket");
                        let queueId = msg.entityData.queueUnit ?
                                        msg.entityData.queueUnit.id :
                                        msg.entityData.queueUnits && msg.entityData.queueUnits.jadwal ? 
                                        msg.entityData.queueUnits.jadwal.id : 
                                        msg.entityData.queueUnits && msg.entityData.queueUnits[0] ? 
                                        msg.entityData.queueUnits[0].id :
                                        'J0'

                        if(viewCaller == 'DOCTOR_CALLER') {
                            clearTimeout(this.timeOutTick)
                            let check = this.tickAll.includes(JSON.stringify(msg))
                            this.tickAll.push(JSON.stringify(msg))
                            this.timeOutTick = setTimeout(()=> {
                                this.tickAll = []
                            }, 1000)
                            if(!check && msg.entityType == 'com.mandayahospital.gateway.external.domain.Queue') {
                                const jadwalId = msg.entityData.queueUnit ? msg.entityData.queueUnit.jadwal.id : ''
                                if(this.jadwal && this.jadwal.id === jadwalId) {
                                    this.isCheckTelekonsul = true
                                    // tunggu 5 detik untuk menutup cek telekonsul
                                    setTimeout(()=> {
                                        this.isCheckTelekonsul = false
                                    }, 5000)
                                    toastr.clear()
                                    if(msg.entityOperation == 'PERSIST') {
                                        toastr.info(`Ada Penambahan Antrian Doctor Caller`)
                                    } else {
                                        toastr.info(`Ada Perubahan Antrian Doctor Caller`)
                                    }
                                    this.msgData = msg.entityOperation == 'PERSIST' ? 'Penambahan' : 'Perubahan'
                                    document.dispatchEvent(event);
                                }
                            } else if (this.isCheckTelekonsul && msg.entityType == 'com.mandayahospital.microservice.telemedicine.domain.Teleconsult') {
                                this.getDoctorCallerQueue()
                            } else if (!check && msg.entityType == 'com.mandayahospital.gateway.external.domain.Reservasi') {
                                toastr.clear()
                                if(msg.entityOperation == 'UPDATE') {
                                    document.dispatchEvent(event);
                                }
                            }
                        } else if( viewCaller == 'UNIT_CALLER') {
                            if(msg.entityType == 'com.mandayahospital.gateway.external.domain.Queue') {
                                if(queueId == `U${this.unitId}`) {
                                    toastr.clear()
                                    if(msg.entityOperation == 'PERSIST') {
                                        toastr.info(`Ada Penambahan Unit Caller`)
                                    } else {
                                        toastr.info(`Ada Perubahan Unit Caller`)
                                    }
                                    this.msgData = msg.entityOperation == 'PERSIST' ? 'Penambahan' : 'Perubahan'
                                    document.dispatchEvent(event);
                                }
                            }
                        }
                    }

                    });
                    },
                    error => {
                        this.disconnect()
                        setTimeout(() => {
                            this.connect();
                        }, 500)
                    // console.log('STOMP Closed',error);
                    }
                );
            },
            basketball(){
                function addClickHandler(handler) {
                    document.addEventListener('basket', handler);
                }

                function removeClickHandler(handler) {
                    document.removeEventListener('basket', handler);
                }
                const clicks = fromEventPattern(
                    addClickHandler,
                    removeClickHandler
                );
                // debounce with rxjs
                const result = clicks.pipe(debounceTime(1000));
                result.subscribe(x => {
                        if(sessionStorage.getItem('viewCaller') !== 'RECENT_CALLER'){
                             let msg = {}
                            if(sessionStorage.getItem('viewCaller') =='UNIT_CALLER'){
                            this.getDataFromApi(sessionStorage.getItem('selectedUnit'))
                                var isActive = window.document.hasFocus()
                                msg = {
                                    body: `Info! \n${this.msgData} di Unit ${this.unitQueue}`
                                }
                                if (!isActive) this.showNotif('Unit Caller', msg)
                            } else {
                                this.getDoctorCallerQueue()
                                var isActive = window.document.hasFocus()
                                msg = {
                                    body: `Info! \n${this.msgData} di Antrian  ${this.dokter.nama}`
                                }
                                if (!isActive) this.showNotif('Doctor Caller', msg)
                            }
                             this.msgData = ''
                        }
                });
            },
            showNotif(title, msg){
               
                if (!("Notification" in window)) {
                    alert("This browser does not support desktop notification");
                }

                else if (Notification.permission === "granted") {

                    var notification = new Notification(title, msg);
                    notification.onclick = function () { 
                        window.parent.parent.focus();
                    }
                }

                else if (Notification.permission !== "denied") {
                    Notification.requestPermission().then(function (permission) {
                        if (permission === "granted") {
                            var notification = new Notification(title, msg);
                        }
                    });
                }

                if(notification !== undefined && notification !== null){
                    // console.log('masuk');
                    notification.onclick = function(event) {
                    window.focus();
                    }
                }else{
                    // console.log('sini');
                    toastr.clear()
                    toastr.error('please allow notification', '', {preventDuplicates: true});
                    // console.log('please allow notification');
                }
            },
            disconnect() {
                sessionStorage.removeItem('viewCaller')
                if (this.stompClient && this.stompClient.status === 'CONNECTED') {
                    this.stompClient.disconnect();
                    // console.log('disconnected');
                }
            },
            checkPermission: function(method, menuShow) {
                const validPermission = checkPermission.isCanAccess(method);
                return validPermission;
            },
            async getServerList(){
                const { data } = await PrinterResourceRepository.getBackOfficeEnv()
                this.setTenantPrinter(data.printingService.tenantPrinter)
            
            },
        },
        created () {
            this.onInit();
            this.connect();
            this.basketball();
            
        },
        mounted(){
            this.getServerList()
            // if(this.checkPermission('AllowDokterCaller')){
            //     this.setViewCaller(this.callerType.OPTION_CALLER)
            // }
        },
        destroyed(){
            this.disconnect()
        }
    }
</script>

<style lang="scss" scoped>

</style>
